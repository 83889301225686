import * as React from "react";
import Header from "./header";
import Footer from "./footer";
import SEOHead from "../SEO";
import { useEffect } from "react";

const Layout = ({ children }) => {
  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/67ab1b853a842732607cea1a/1ijq670o2';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }, []);

  return (
    <>
      <SEOHead
        title={"Sparkle Infotech - Your Top Rated Plus Outsourcing Solution"}
        description={
          "Sparkle Infotech delivers custom web development services worldwide. Our expert team helps businesses achieve goals with innovative digital solutions."
        }
        url={process.env.NEXT_PUBLIC_SITE_URL}
        twitterHandler={"discussionfora1"}
        imageUrl={`${process.env.NEXT_PUBLIC_SITE_URL}/uploads/sparkleinfotechseo_80a1ccf6b9.png`}
      />
      <Header siteTitle={`Title`} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;